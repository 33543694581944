import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Cookies from "js-cookie";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Navbar from "./components/layout/navbar";
import Header from "./components/layout/Header";
import SubHeader from "./components/layout/SubHeader";
import SubHeaderDesc from "./components/layout/SubHeaderDesc";
import FileIconImage from "common/src/assets/image/saasModern/file-icon.png";
import Button from "@material-ui/core/Button";

const styles = () => ({
  Image: {
    marginLeft: 10,
    width: 80,
    height: 100
  }
});

class einSuccess extends Component {
  state = {
    entityName: "",
    einNumber: "",
    einLetterURL: ""
  };

  componentDidMount() {
    this.setState({
      entityName: this.props.location.state.entityName,
      einNumber: this.props.location.state.einNumber,
      einLetterURL: this.props.location.state.einLetterURL
    });
    Cookies.remove("filingReason");
    Cookies.remove("email");
    Cookies.remove("firstName");
    Cookies.remove("lastName");
    Cookies.remove("phone");
    Cookies.remove("ssn");
    Cookies.remove("addressLine1");
    Cookies.remove("addressLine2");
    Cookies.remove("city");
    Cookies.remove("state");
    Cookies.remove("zipcode");
    Cookies.remove("value");
    Cookies.remove("invalidAddress");
    Cookies.remove("entityName");
    Cookies.remove("entityType");
    Cookies.remove("membersNumber");
    Cookies.remove("entityState");
    Cookies.remove("businessCategory");
  }

  render() {
    const { classes } = this.props;
    const { entityName, einNumber, einLetterURL } = this.state;
    return (
      <div>
        <Navbar />
        <div style={{ margin: 20 }}>
          <Fade in={true} timeout={1200}>
            <Slide
              direction="up"
              in={true}
              mountOnEnter
              unmountOnExit
              timeout={500}
            >
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Header>EIN Request Successful</Header>
                <br />
                <img
                  src={FileIconImage}
                  alt="file-icon"
                  className={classes.Image}
                />
                <br />
                <SubHeader>{entityName}</SubHeader>
                <SubHeader>EIN Number: {einNumber}</SubHeader>
                <div style={{ maxWidth: 500, textAlign: "center" }}>
                  <SubHeaderDesc>
                    We have successfully filed an EIN / Tax ID with the IRS for
                    you. You can now download your EIN Letter and store it for
                    your records. Additionaly a copy of your EIN Number and
                    Letter will be emailed to you.
                  </SubHeaderDesc>
                </div>
                <br />
                <Button
                  onClick={() => window.open(einLetterURL, "_blank")}
                  variant="contained"
                  color="primary"
                  size="large"
                  buttonstyle={{ borderRadius: 25 }}
                  style={{
                    borderRadius: 25,
                    textTransform: "none",
                    width: 300,
                    alignItems: "center",
                    justify: "center"
                  }}
                >
                  Download EIN Letter
                </Button>
              </Grid>
            </Slide>
          </Fade>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default withStyles(styles)(einSuccess);
