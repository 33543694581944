import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import LogoImageAlt from "common/src/assets/image/saasModern/logo.png";

const styles = () => ({
  Navbar: {
    backgroundColor: "white",
    boxShadow: "none",
    maxHeight: "50vh"
  },
  Image: {
    maxWidth: "50vw",
    maxHeight: "40px",
  }
});

class Navbar extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <AppBar position="static" className={classes.Navbar}>
        <Toolbar>
          <Link to="/">
            <img src={LogoImageAlt} alt="logo" className={classes.Image} />
          </Link>
        </Toolbar>
      </AppBar>
    );
  }
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Navbar);
