import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = () => ({
  subheader: {
    fontFamily: "Basetica,Roboto",
    fontWeight: "300",
    fontSize: "12pt"
  }
});

class SubHeader extends PureComponent {
  render() {
    const { classes, children } = this.props;
    return (
      <Typography variant="h5" gutterBottom className={classes.subheader}>
        {children}
      </Typography>
    );
  }
}

SubHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(SubHeader);
