import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  header: {
    fontFamily: 'Basetica,Roboto',
    fontWeight: 'Bold',
    fontSize: '22pt',
  },
});

class header extends PureComponent {
  render() {
    const { classes, children } = this.props;
    return (
      <Typography variant="h2" gutterBottom className={classes.header}>
        {children}
      </Typography>
    );
  }
}

header.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(header);
